"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Event = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class Event {
  constructor(id, title, startDate, endDate, challengeTemplate) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.challengeTemplate = challengeTemplate;
    const now = (0, _moment.default)();
    if (endDate.isBefore(now)) this.period = 'past';else if (startDate.isAfter(now)) this.period = 'future';else this.period = 'current';
  }
}
exports.Event = Event;